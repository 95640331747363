import React, { useState } from 'react';
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
// import { Link } from 'react-router-dom';

// import './Login.css';

import '../css/Login.css';

const firebaseConfig = {
  apiKey: "AIzaSyA_Emc7nAMDRuBRmJJaDeT-XMXnV2pf3Ss",
  authDomain: "markethub-70f1a.firebaseapp.com",
  databaseURL: "https://markethub-70f1a-default-rtdb.firebaseio.com",
  projectId: "markethub-70f1a",
  storageBucket: "markethub-70f1a.appspot.com",
  messagingSenderId: "146001622616",
  appId: "1:146001622616:web:ba5b06b8b4bb6f6e15bc2d",
  measurementId: "G-HV1KXJB8WG"
};

//   // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  // const analytics = getAnalytics(app);
  const auth = getAuth(app);


const signUp = async (email, password) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    console.log("User signed up:", userCredential.user);
    return { user: userCredential.user };
  } catch (error) {
    console.error("Error signing up:", error.message);
    return { error: error.message };
  }
};

const login = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    console.log("User logged in:", userCredential.user);
    return { user: userCredential.user };
  } catch (error) {
    console.error("Login error:", error.message);
    return { error: error.message };
  }
};


// function Login() {
const Login = ({ onLogin }) => {

  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   // TODO: Add your authentication logic here
  //   console.log('Login attempted with:', email, "; and password:", password);
  //     console.log('Signing in');
  //     login(email, password);
  //     console.log('Login successful');
  //   // signUp(email, password).then(n => {
  //   //   console.log('Signup successful');
  //   // }).then(n => {
  //   //   console.log('Signing in');
  //   //   login(email, password);
  //   //   console.log('Login successful');
  //   // });
  // };

  // const handleSignUp = (e) => {
  //   e.preventDefault();
  //   console.log('Signing up');
  //   console.log('Signing up with:', email, "; and password:", password);
  //   signUp(email, password);
  //   console.log('Signup successful');
  // };


  const [isLoginMode, setIsLoginMode] = useState(true);
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [validationErrors, setValidationErrors] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [signupSuccess, setSignupSuccess] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);

  // Add validation functions
  const validateUsername = (username) => {
    if (username.length < 3) {
      return 'Username must be at least 3 characters long';
    }
    if (username.length > 20) {
      return 'Username must be less than 20 characters';
    }
    if (!/^[a-zA-Z0-9_]+$/.test(username)) {
      return 'Username can only contain letters, numbers and underscores';
    }
    return '';
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return 'Please enter a valid email address';
    }
    return '';
  };

  const validatePassword = (password) => {
    if (password.length < 8) {
      return 'Password must be at least 8 characters long';
    }
    if (!/[A-Z]/.test(password)) {
      return 'Password must contain at least one uppercase letter';
    }
    if (!/[a-z]/.test(password)) {
      return 'Password must contain at least one lowercase letter';
    }
    if (!/[0-9]/.test(password)) {
      return 'Password must contain at least one number';
    }
    return '';
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    // Validate on change
    let error = '';
    switch (name) {
      case 'username':
        error = validateUsername(value);
        break;
      case 'email':
        error = validateEmail(value);
        break;
      case 'password':
        error = validatePassword(value);
        break;
      case 'confirmPassword':
        error = value !== formData.password ? "Passwords don't match" : '';
        break;
      default:
        break;
    }

    setValidationErrors(prev => ({
      ...prev,
      [name]: error
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    // Validate fields based on mode
    let hasErrors = false;
    if (isLoginMode) {
        // For login, only validate email and password
        const emailError = validateEmail(formData.email);
        const passwordError = validatePassword(formData.password);
        hasErrors = emailError || passwordError;

        setValidationErrors({
            username: '',
            email: emailError,
            password: passwordError,
            confirmPassword: ''
        });
    } else {
        // For signup, validate all fields
        const usernameError = validateUsername(formData.username);
        const emailError = validateEmail(formData.email);
        const passwordError = validatePassword(formData.password);
        const confirmPasswordError = formData.password !== formData.confirmPassword 
            ? "Passwords don't match" 
            : '';

        hasErrors = usernameError || emailError || passwordError || confirmPasswordError;

        setValidationErrors({
            username: usernameError,
            email: emailError,
            password: passwordError,
            confirmPassword: confirmPasswordError
        });
    }

    if (hasErrors) {
        setIsLoading(false);
        return;
    }

    try {
        if (isLoginMode) {
            const result = await login(formData.email, formData.password);
            if (result && result.error) {
                setError(result.error);
            } else {
                console.log('Login successful');
                setLoginSuccess(true);
                // Clear the form
                setFormData({
                    username: '',
                    email: '',
                    password: '',
                    confirmPassword: ''
                });
            }
        } else {
            const result = await signUp(formData.email, formData.password);
            if (result && result.error) {
                setError(result.error);
            } else {
                console.log('Signup successful');
                setSignupSuccess(true);
                setIsLoginMode(true); // Switch to login mode
                setFormData({
                    username: '',
                    email: '',
                    password: '',
                    confirmPassword: ''
                });
            }
        }
    } catch (err) {
        setError(err.message || 'An error occurred');
    } finally {
        setIsLoading(false);
    }
  };

  // Add this function to reset the login state
  const resetLoginState = () => {
    setLoginSuccess(false);
    setSignupSuccess(false);
    setError(null);
    setFormData({
      username: '',
      email: '',
      password: '',
      confirmPassword: ''
    });
    setIsLoginMode(true);
  };

  // Modify toggleMode to use resetLoginState
  const toggleMode = (e) => {
    e.preventDefault();
    resetLoginState();
    setIsLoginMode(!isLoginMode);
  };

  // Add click handler for the Login link
  // const handleLoginClick = (e) => {
  //   if (loginSuccess) {
  //     e.preventDefault();
  //     resetLoginState();
  //   }
  // };

  return (
    <>
        {loginSuccess ? (
            <div className="success-container">
                <div className="success-message">
                    Welcome! You have successfully logged in.
                </div>
                <div>
                  <p>THIS IS LOGIN!!!!!!</p>
                </div>
            </div>
        ) : (
            <div className="login-container">
                <form onSubmit={handleSubmit} className="login-form">
                    <h1>{isLoginMode ? 'Login' : 'Sign Up'}</h1>
                    
                    {error && <div className="error-message">{error}</div>}
                    {signupSuccess && (
                        <div className="success-message">
                            Successfully signed up! Please log in with your credentials.
                        </div>
                    )}
                    
                    {!isLoginMode && (
                    <div className="form-group">
                      <label htmlFor="username">Username:</label>
                      <input
                        id="username"
                        type="text"
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                        required
                        autoComplete="username"
                        className={validationErrors.username ? 'input-error' : ''}
                      />
                      {validationErrors.username && (
                        <div className="validation-error">{validationErrors.username}</div>
                      )}
                    </div>
                    )}


                    {(
                      <div className="form-group">
                        <label htmlFor="email">Email:</label>
                        <input
                          id="email"
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                          autoComplete="email"
                          className={validationErrors.email ? 'input-error' : ''}
                        />
                        {validationErrors.email && (
                          <div className="validation-error">{validationErrors.email}</div>
                        )}
                      </div>
                    )}

                    <div className="form-group">
                      <label htmlFor="password">Password:</label>
                      <input
                        id="password"
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                        autoComplete={isLoginMode ? "current-password" : "new-password"}
                        className={validationErrors.password ? 'input-error' : ''}
                      />
                      {validationErrors.password && (
                        <div className="validation-error">{validationErrors.password}</div>
                      )}
                    </div>

                    {!isLoginMode && (
                      <div className="form-group">
                        <label htmlFor="confirmPassword">Confirm Password:</label>
                        <input
                          id="confirmPassword"
                          type="password"
                          name="confirmPassword"
                          value={formData.confirmPassword}
                          onChange={handleChange}
                          required
                          autoComplete="new-password"
                          className={validationErrors.confirmPassword ? 'input-error' : ''}
                        />
                        {validationErrors.confirmPassword && (
                          <div className="validation-error">{validationErrors.confirmPassword}</div>
                        )}
                      </div>
                    )}

                    <button 
                      type="submit" 
                      disabled={isLoading}
                      className="submit-button"
                    >
                      {isLoading ? 'Processing...' : (isLoginMode ? 'Login' : 'Sign Up')}
                    </button>

                    <div className="toggle-mode">
                      <p>
                        {isLoginMode ? "Don't have an account? " : "Already have an account? "}
                        <a href={isLoginMode ? '/signup' : '/login'} onClick={toggleMode} className="toggle-link">
                          {isLoginMode ? 'Sign Up' : 'Login'}
                        </a>
                        {/* <button 
                          type="button" 
                          onClick={toggleMode}
                          className="toggle-button"
                        >
                          {isLoginMode ? 'Sign Up' : 'Login'}
                        </button> */}
                      </p>
                    </div>
                </form>
            </div>
        )}
    </>
  );
  // return (
  //   <div className="login-container">
  //     <form onSubmit={handleSubmit} className="login-form">
  //       <h2>Login</h2>
  //       <div className="form-group">
  //         <label>Email:</label>
  //         <input
  //           type="email"
  //           value={email}
  //           onChange={(e) => setEmail(e.target.value)}
  //           autoComplete="email"
  //           required
  //         />
  //       </div>
  //       <div className="form-group">
  //         <label>Password:</label>
  //         <input
  //           type="password"
  //           value={password}
  //           onChange={(e) => setPassword(e.target.value)}
  //           autoComplete="current-password"
  //           required
  //         />
          
  //       </div>
  //       <button type="submit">Login</button>
  //       <p></p>
  //       <button onClick={handleSignUp}>Sign Up</button>
  //     </form>
  //   </div>
  // );

}

export default Login;